<template>
  <div>
    <!-- <div @click="showSort = !showSort">筛选</div>
    <div v-if="showSort" class="sortContain">
      <div
        class="sortList"
        @click="sortChange(item)"
        v-for="(item, index) in sortList"
        :key="index"
      >
        <div v-if="item.name">{{ item.name }}</div>
        <div>
          {{ item }}
        </div>
      </div>
    </div> -->

    <div
      ref="descTitle"
      :style="descWidth ? 'padding: 10px 0;' : 'padding: 19px 0;'"
      style="
        display: flex;
        justify-content: flex-end;

        width: 96%;
      "
    >
      <div class="descMain" style="margin-bottom: 10px">
        <div
          :class="descWidth ? 'tipsTitlefixWidth' : 'tipsTitle'"
          v-for="(column, columnIndex) in columns"
          :key="columnIndex"
        >
          <span v-if="column.name">
            {{ column.name }}
            <span
              v-if="column.name.indexOf('环比') > -1"
              :class="`tips tips${columnIndex + 1}`"
              :style="{ background: 'green' }"
            ></span>
            <span
              v-else
              :class="`tips tips${columnIndex + 1}`"
              :style="{ background: column.color ? column.color : '' }"
            ></span>
          </span>
          <span v-else>
            {{ column }}
            <span
              v-if="column.indexOf('环比') > -1"
              :style="{ background: 'green' }"
              :class="`tips tips${columnIndex + 1}`"
            >
            </span>
            <span v-else :class="`tips tips${columnIndex + 1}`"> </span>
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="showList.length > 0"
      class="listSty"
      :style="{ height: listHeight }"
    >
      <div v-for="(item, key) in showList" :key="key">
        <div
          class="contentMainItem"
          style="
            box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
            padding: 7px 4px 7px 20px;
          "
        >
          <span v-if="key + 1 <= 10" class="number bg01">{{ key + 1 }}</span>
          <span v-if="key + 1 <= 50 && key + 1 > 10" class="number bg02">{{
            key + 1
          }}</span>
          <span v-if="key + 1 <= 100 && key + 1 > 50" class="number bg03">{{
            key + 1
          }}</span>
          <span v-if="key + 1 <= 200 && key + 1 > 100" class="number bg04">{{
            key + 1
          }}</span>
          <span
            v-if="key + 1 > 200"
            class="number"
            style="background: #515a6e"
            >{{ key + 1 }}</span
          >

          <div
            v-if="titleSelfLine && titleSelfTitle"
            style="
              width: 100%;
              text-align: left;
              color: #aeacac;
              display: block;
            "
          >
            {{ item[titleSelfTitle] }}
          </div>

          <div
            v-if="
              (item[title] ||
                item['单位名称'] ||
                item['单位'] ||
                item['姓名'] ||
                item['name'] ||
                item['deptName']) &&
              singelTitle
            "
            class="titleOverFlow"
            :style="{ width: columns.length <= 3 ? '40%' : '30%' }"
          >
            {{
              item[title] ||
              item["单位名称"] ||
              item["单位"] ||
              item["name"] ||
              item["姓名"] ||
              item["deptName"]
            }}
          </div>

          <!-- 多个左侧表头 -->
          <div
            v-if="!singelTitle"
            class="titleMulti"
            :style="{ width: columns.length <= 3 ? '40%' : '30%' }"
          >
            <div v-for="(titleItem, index) in multiTitleArr" :key="index">
              {{ item[titleItem.value] }}
            </div>
          </div>

          <div style="flex: 1" class="contentMainItem">
            <!-- :class="
                columns.length <= 3
                  ? `contentItemCommon mainTitleTap${columnIndex + 1}`
                  : `contentItem mainTitleTap${columnIndex + 1}`
                  ? columns[columnIndex] && columns[columnIndex]['selfValLine']
                  : 'selfValLineClass'
              " -->
            <!-- -->

            <div
              :class="
                columns.length <= 3
                  ? `contentItemCommon mainTitleTap${columnIndex + 1}`
                  : `contentItem mainTitleTap${columnIndex + 1}`
              "
              :id="
                columns[columnIndex] && columns[columnIndex]['selfValLine']
                  ? 'selfValLineClass'
                  : ''
              "
              :style="{
                color:
                  columns[columnIndex] && columns[columnIndex]['color']
                    ? columns[columnIndex]['color']
                    : '',
                padding: '4px 0',
              }"
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              @click="chooseItem(item)"
            >
              {{ columnIndex["value"] }}

              <div v-if="column.name">
                <span
                  v-if="column == '环比增长' || '同比增长' == column"
                  style=""
                >
                  <span
                    class="tipsHuanbiupColor"
                    v-if="
                      item[columns[columnIndex]['value']] &&
                      item[columns[columnIndex]['value']].indexOf('+') > -1
                    "
                  >
                    {{ item[columns[columnIndex]["value"]] }}
                  </span>
                  <span v-else class="tipsHuanbiDownColor">
                    {{ item[columns[columnIndex]["value"]] }}
                  </span>
                </span>
                <span v-else> {{ item[columns[columnIndex]["value"]] }}</span>
              </div>
              <div v-else>
                <span
                  v-if="column == '环比增长' || '同比增长' == column"
                  style=""
                >
                  <span
                    class="tipsHuanbiupColor"
                    v-if="
                      item[columns[columnIndex]] &&
                      item[columns[columnIndex]].indexOf('+') > -1
                    "
                  >
                    {{ item[columns[columnIndex]] }}
                  </span>
                  <span v-else class="tipsHuanbiDownColor">
                    {{ item[columns[columnIndex]] }}
                  </span>
                </span>
                <span v-else> {{ item[columns[columnIndex]] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showList.length > 0 && huizongList.length > 0">
        <div v-for="(item, key) in huizongList" :key="`huizong${key}`">
          <div
            class="contentMainItem"
            style="
              box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
              padding: 7px 4px 7px 20px;
            "
          >
            <span v-if="key + 1 <= 10" class="number bg01">{{ key + 1 }}</span>
            <span v-if="key + 1 <= 50 && key + 1 > 10" class="number bg02">{{
              key + 1
            }}</span>
            <span v-if="key + 1 <= 100 && key + 1 > 50" class="number bg03">{{
              key + 1
            }}</span>
            <span v-if="key + 1 <= 200 && key + 1 > 100" class="number bg04">{{
              key + 1
            }}</span>
            <span
              v-if="key + 1 > 200"
              class="number"
              style="background: #515a6e"
              >{{ key + 1 }}</span
            >

            <div
              v-if="titleSelfLine && titleSelfTitle"
              style="
                width: 100%;
                text-align: left;
                color: #aeacac;
                display: block;
              "
            >
              {{ item[titleSelfTitle] }}
            </div>

            <div
              v-if="
                (item[title] ||
                  item['单位名称'] ||
                  item['单位'] ||
                  item['姓名'] ||
                  item['name'] ||
                  item['deptName']) &&
                singelTitle
              "
              class="titleOverFlow"
              :style="{ width: columns.length <= 3 ? '40%' : '30%' }"
            >
              {{
                item[title] ||
                item["单位名称"] ||
                item["单位"] ||
                item["name"] ||
                item["姓名"] ||
                item["deptName"]
              }}
            </div>

            <!-- 多个左侧表头 -->
            <div
              v-if="!singelTitle"
              class="titleMulti"
              :style="{ width: columns.length <= 3 ? '40%' : '30%' }"
            >
              <div v-for="(titleItem, index) in multiTitleArr" :key="index">
                {{ item[titleItem.value] }}
              </div>
            </div>

            <div style="flex: 1" class="contentMainItem">
              <!-- :class="
                  columns.length <= 3
                    ? `contentItemCommon mainTitleTap${columnIndex + 1}`
                    : `contentItem mainTitleTap${columnIndex + 1}`
                    ? columns[columnIndex] && columns[columnIndex]['selfValLine']
                    : 'selfValLineClass'
                " -->
              <!-- -->

              <div
                :class="
                  columns.length <= 3
                    ? `contentItemCommon mainTitleTap${columnIndex + 1}`
                    : `contentItem mainTitleTap${columnIndex + 1}`
                "
                :id="
                  columns[columnIndex] && columns[columnIndex]['selfValLine']
                    ? 'selfValLineClass'
                    : ''
                "
                :style="{
                  color:
                    columns[columnIndex] && columns[columnIndex]['color']
                      ? columns[columnIndex]['color']
                      : '',
                  padding: '4px 0',
                }"
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                @click="chooseItem(item)"
              >
                {{ columnIndex["value"] }}

                <div v-if="column.name">
                  <span
                    v-if="column == '环比增长' || '同比增长' == column"
                    style=""
                  >
                    <span
                      class="tipsHuanbiupColor"
                      v-if="
                        item[columns[columnIndex]['value']] &&
                        item[columns[columnIndex]['value']].indexOf('+') > -1
                      "
                    >
                      {{ item[columns[columnIndex]["value"]] }}
                    </span>
                    <span v-else class="tipsHuanbiDownColor">
                      {{ item[columns[columnIndex]["value"]] }}
                    </span>
                  </span>
                  <span v-else> {{ item[columns[columnIndex]["value"]] }}</span>
                </div>
                <div v-else>
                  <span
                    v-if="column == '环比增长' || '同比增长' == column"
                    style=""
                  >
                    <span
                      class="tipsHuanbiupColor"
                      v-if="
                        item[columns[columnIndex]] &&
                        item[columns[columnIndex]].indexOf('+') > -1
                      "
                    >
                      {{ item[columns[columnIndex]] }}
                    </span>
                    <span v-else class="tipsHuanbiDownColor">
                      {{ item[columns[columnIndex]] }}
                    </span>
                  </span>
                  <span v-else> {{ item[columns[columnIndex]] }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showLoadMore && showList.length <= 0 && !showLoad"
      style="width: 100%; padding: 10px 0; text-align: center; color: #795548"
    >
      没有更多数据了
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

export default {
  name: "listCom",
  components: {},
  data() {
    return {
      showSort: false,
      listHeight: "80vh",
    };
  },
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },

    showList: {
      type: Array,
      default: () => {
        return [];
      },
    },

    huizongList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },
    titleSelfLine: {
      type: Boolean,
      default: false,
    },
    titleSelfTitle: {
      type: String,
      default: "",
    },
    descWidth: {
      type: Boolean,
      default: false,
    },
    // 排序规则
    sortList: {
      type: Array,
      default: () => {
        return [];
      },
    },

    // 是否加载完成
    showLoad: {
      type: Boolean,
      default: false,
    },

    showLoadMore: {
      type: Boolean,
      default: true,
    },
    singelTitle: {
      type: Boolean,
      default: true,
    },
    multiTitleArr: {
      type: Array,
      default: () => {
        return [];
      },
    },

    comHeight: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    chooseItem(data) {
      this.$emit("chooseListItem", data);
    },
    sortChange(item) {
      // console.log("----", item);
    },
    getListHeight() {
      const _clientHeight =
        document.documentElement.clientHeight ||
        document.body.clientHeight ||
        window.innerHeight;

      // console.log(
      //   "descTitle top=----",
      //   "offsetTop:",
      //   this.$refs.descTitle.offsetTop,
      //   "clientHeight:",
      //   this.$refs.descTitle.clientHeight,
      //   "offsetHeight:",
      //   this.$refs.descTitle.offsetHeight,

      //   _clientHeight
      //   // parseFloat(this.$refs.descTitle.offsetTop)
      // );
      if (_clientHeight && parseFloat(_clientHeight)) {
        const listHeight =
          ((parseFloat(_clientHeight) -
            (parseFloat(this.$refs.descTitle.offsetTop) +
              parseFloat(this.$refs.descTitle.clientHeight))) /
            parseFloat(_clientHeight)) *
          100;

        console.log("descTitle top=----", listHeight);

        if (listHeight) {
          this.listHeight = `${parseFloat(listHeight) - this.comHeight}vh`;
        }
      }
    },
  },
  mounted() {
    this.getListHeight();
  },
  async created() {},
};
</script>

<style scoped>
.contentMainItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  justify-content: flex-end;
  position: relative;
}
.contentItem {
  flex: 1;
  width: 25%;
  min-width: 25%;
  max-width: 25%;
  text-align: center;
  font-weight: bold;
}
.contentItemCommon {
  flex: 1;

  text-align: center;
  font-weight: bold;
}
.tipsTitle {
  /* flex: 1; */
  /* flex: 1;
  width: 25%;
  min-width: 25%;
  max-width: 25%; */
  margin: 0 8px 0 0;
}

.tipsTitlefixWidth {
  font-size: 12px;
  color: #616161;
  width: 25%;
  min-width: 25%;
  max-width: 25%;
}

.descMain {
  max-width: 78%;
  width: 78%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  text-align: right;
}

.number {
  display: inline-block;
  padding: 0 3px;
  /* width: 20px; */
  height: 15px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.numberGrey {
  background: #757575;
}

.bg01 {
  background: rgb(202, 9, 21);
}
.bg02 {
  background: rgb(252, 103, 46);
}
.bg03 {
  background: rgb(176, 194, 40);
}
.bg04 {
  background: rgb(105, 175, 120);
}

.titleMulti {
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-direction: column;
}
.titleMulti div:not(:first-child) {
  margin-top: 5px;
  color: #515a6e;
  font-size: 12px;
}

@keyframes mymove {
  from {
    left: 0px;
  }
  to {
    left: 200px;
  }
}

.sortContain {
  width: 100px;
  height: 100px;
  background: blue;
  transition: mymove 4s ease-in-out 1s;
  -webkit-transition-delay: 300ms;
  -o-transition-delay: 300ms;
  -moz-transition-delay: 300ms;
  -ms-transition-delay: 300ms;
  transition-delay: 300ms;
}
.listSty {
  width: 96%;
  margin: 0 auto;

  overflow: scroll;
}
#selfValLineClass {
  width: 100%;
  min-width: 100%;
  text-align: right;
  max-width: 100%;
  margin-right: 3px;
}
</style>
