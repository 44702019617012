<template>
  <div class="noscrollmain">
    <common-top
      title="阅读排行"
      :isShowSearch="false"
      :isFilter="true"
      :sortList="sortList"
      @sortData="sortData"
      @search="showModal = true"
    ></common-top>

    <Modal v-model="showDetailStatus" fullscreen>
      <p slot="header" style="text-align: center">
        <span>{{ detailInfo["deptName"] || detailInfo["单位"] }}</span>
      </p>
      <div>
        <div
          style="padding: 10px 0; text-align: left; width: 98%; margin: 0 auto"
        >
          {{ detailInfo["title"] }}
        </div>
      </div>
      <div>
        <div class="deptMainTop">
          <div class="box-item commonBorder">
            <span>
              <span>
                <svg
                  t="1647753036303"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  p-id="3029"
                  width="32"
                  height="32"
                >
                  <path
                    d="M430.602 443.728H172.941v409.721h257.661l9.723 22.763h123.159l12.965-22.763h251.179V437.225z"
                    fill="#ED8F27"
                    p-id="3030"
                  ></path>
                  <path
                    d="M563.484 888.712H440.325l-11.495-7.59-6.48-15.173H172.94l-12.5-12.5v-409.72l12.5-12.5H430.5l396.923-6.502 12.705 12.498V853.45l-12.5 12.5H583.714l-9.367 16.449-10.863 6.313z m-114.906-25h107.641l9.367-16.449 10.862-6.313h238.68V449.931l-384.525 6.298H185.44V840.95h245.162l11.495 7.59 6.481 15.172z"
                    fill="#300604"
                    p-id="3031"
                  ></path>
                  <path
                    d="M772.531 386.823s-221.458-42.273-266.298 50.402l-22.982 384.568h52.685l4.862-31.937h231.733V386.823z"
                    fill="#FCE3C3"
                    p-id="3032"
                  ></path>
                  <path
                    d="M535.936 831.793h-52.685l-9.982-10.597 22.981-384.568 0.98-3.759c11.444-23.653 33.769-41.182 66.351-52.099 24.92-8.351 56.024-12.892 92.449-13.499 61.296-1.015 116.074 9.29 118.376 9.729l8.125 9.822v403.034l-10 10H549.39l-3.568 23.441-9.886 8.496z m-42.07-20h33.477l3.568-23.441 9.886-8.495h221.734V395.259c-17.44-2.796-60.6-8.781-106.461-7.984-93.223 1.611-127.655 29.096-139.979 52.604l-22.225 371.914z"
                    fill="#300604"
                    p-id="3033"
                  ></path>
                  <path
                    d="M224.797 386.823H469.99v403.034H224.797z"
                    fill="#FCE3C3"
                    p-id="3034"
                  ></path>
                  <path
                    d="M469.99 799.856H224.797l-10-10V386.822l10-10H469.99l10 10v403.034l-10 10z m-235.193-20H459.99V396.822H234.797v383.034z"
                    fill="#300604"
                    p-id="3035"
                  ></path>
                  <path
                    d="M292.542 310.406s139.34 22.474 190.709 81.026c27.337 31.16 26.78 73.506 26.78 73.506v317.628c0 12.645-6.106 24.506-16.384 31.825l-10.396 7.403s0.118-26.71-27.886-44.677-162.823-66.744-162.823-66.744V310.406z"
                    fill="#FCE3C3"
                    p-id="3036"
                  ></path>
                  <path
                    d="M489.052 829.938l-15.801-8.189c-0.013-0.789-0.575-21.645-23.286-36.216-21.401-13.731-114.557-49.018-160.825-65.758l-6.598-9.403V310.406l11.593-9.872c5.834 0.94 143.502 23.743 196.634 84.304 29.188 33.269 29.287 77.311 29.262 80.148v317.579c0 15.851-7.694 30.793-20.583 39.971l-10.396 7.402z m-186.51-126.567c27.82 10.177 132.917 49.092 158.224 65.328 17.225 11.052 25.318 25.077 29.12 35.95a29.13 29.13 0 0 0 10.145-22.084l0.001-317.76c0.003-0.365 0.059-39.017-24.297-66.778-40.047-45.646-139.256-68.81-173.192-75.589v380.933zM610.066 444.946h123.76v27.902h-123.76zM610.066 510.959h123.76v27.902h-123.76zM665.936 689.082h62.532v27.902h-62.532zM633.087 194.47l16.4 11.448-80.858 115.834-16.4-11.448zM725.26 204.73l13.734 14.54L634.785 317.7l-13.733-14.539zM800.489 240.542l13.142 15.076-52.696 45.935-13.142-15.076zM468.638 187.397l27.52 56.292-17.968 8.784-27.52-56.292zM331.501 204.24l120.28 98.428-12.666 15.478-120.28-98.427zM243.412 238.813l42.402 17.49-7.626 18.488-42.402-17.49z"
                    fill="#300604"
                    p-id="3037"
                  ></path>
                </svg>
              </span>
            </span>

            <span class="descColor"> 阅读 </span>
            <span class="numColor">
              {{ detailInfo["count"] || 0 }}
            </span>
          </div>

          <div class="box-item commonBorder">
            <span>
              <span>
                <svg
                  t="1647759201115"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  p-id="30404"
                  width="32"
                  height="32"
                >
                  <path
                    d="M180.2 363.5h133.1c15.6 0 30.8-4.9 43.5-14l29.1-20.9c66.1-47.5 106.9-122.5 110.9-203.8C497.9 102 516.7 84 539.6 84h4.8c20.7 0 35.2 7 51 28.1 48.4 60.4 50.3 140.4 35.9 214.4l-2.3 12c-4.9 11.9 3.9 24.9 16.7 24.9H828c45.1 0 78.4 8.1 67.2 86.2C873.5 639 818.3 815.7 797 857.4c-12.8 23.7-31.6 39.3-56.6 39.3H209.7c-28.1 0-51.3-21.7-53.3-49.7L127 420.4c-2.2-30.7 22.2-56.9 53.2-56.9z"
                    fill="#FFA820"
                    p-id="30405"
                  ></path>
                  <path
                    d="M630.8 896.9h109.5c25 0 43.8-15.7 56.6-39.3 21.2-41.8 76.5-218.5 98.2-407.8 10.3-71.3-16.6-84.2-55.7-85.9 21.5 49.5 33.4 104.1 33.4 161.6-0.1 165.7-99.5 308.3-242 371.4zM151.8 781.8l4.5 65.4c1.9 28 25.2 49.7 53.3 49.7h93c-59-26.1-110.6-65.8-150.8-115.1zM497.2 120.4c42.4 3.2 83 12.8 120.7 28-6-12.6-13.5-24.8-22.7-36.2-7.8-10.4-15.3-17.4-23.4-21.8-12.8-2.5-25.9-4.4-39-5.8-18.2 2.9-32.8 17.3-35.6 35.8z"
                    fill="#FEB133"
                    p-id="30406"
                  ></path>
                  <path
                    d="M449.1 856.1c-130.5 0-244.2-71.4-304.4-177.3l7.1 103c40.2 49.3 91.8 89 150.7 115.1h328.3c142.5-63 242-205.7 242-371.5 0-57.4-11.9-112-33.4-161.6-3.7-0.2-7.6-0.2-11.5-0.2h-59.2c19.5 43.6 30.3 91.9 30.3 142.7-0.1 193.1-156.7 349.8-349.9 349.8zM496.7 124.8c-0.6 11.6-1.9 23-3.9 34.2 52.3 6.5 101 24.6 143.4 51.5-3.1-21.6-8.9-42.6-18.3-62.3-37.8-15.2-78.3-24.8-120.7-28-0.2 1.6-0.4 3.1-0.5 4.6z"
                    fill="#FEB533"
                    p-id="30407"
                  ></path>
                  <path
                    d="M482.8 198.1c60 10.6 113.6 39.4 155.1 80.4 1.8-22.8 1.6-45.7-1.6-67.9-42.5-27-91.2-45-143.4-51.5-2.5 13.3-5.8 26.3-10.1 39zM431.4 780.6c-162.1 0-293.5-131.4-293.5-293.5 0-43.2 9.3-84.2 26.1-121.1-22.8 7.2-38.8 29.3-37.1 54.5l17.8 258.2C204.8 784.6 318.6 856 449.1 856c193.2 0 349.8-156.6 349.8-349.8 0-50.8-10.8-99.1-30.3-142.7h-70.9c17.5 37.6 27.2 79.4 27.2 123.6 0 162.1-131.4 293.5-293.5 293.5z"
                    fill="#FEBA33"
                    p-id="30408"
                  ></path>
                  <path
                    d="M466.1 236.6C572 260.5 651 355 651 468.1c0 131-106.2 237.3-237.3 237.3S176.5 599.1 176.5 468.1c0-37.5 8.7-73 24.2-104.5h-20.6c-5.6 0-11.1 0.9-16.2 2.5-16.8 36.9-26.1 77.9-26.1 121.1 0 162.1 131.4 293.5 293.5 293.5s293.5-131.4 293.5-293.5c0-44.2-9.8-86-27.2-123.6h-52.1c-12.9 0-21.6-13-16.7-24.9l2.3-12c3.1-15.8 5.4-31.9 6.7-48.1-41.5-41.1-95.2-69.8-155.1-80.4-4.5 13.2-10 26-16.6 38.4z"
                    fill="#FFBE34"
                    p-id="30409"
                  ></path>
                  <path
                    d="M413.7 705.3c131 0 237.3-106.2 237.3-237.3 0-113-79.1-207.6-184.9-231.4-6.9 13.1-14.9 25.6-23.9 37.4 77.6 20.4 134.9 91 134.9 175 0 99.9-81 180.9-180.9 180.9s-180.9-81-180.9-180.9c0-30.9 7.8-60 21.4-85.4h-36c-15.5 31.5-24.2 67-24.2 104.5 0 131 106.2 237.2 237.2 237.2z"
                    fill="#FFC234"
                    p-id="30410"
                  ></path>
                  <path
                    d="M396.2 629.9c99.9 0 180.9-81 180.9-180.9 0-84-57.3-154.6-134.9-175-9.6 12.6-20.4 24.4-32.3 35.2 53.7 13.9 93.3 62.7 93.3 120.7 0 68.9-55.8 124.7-124.7 124.7s-124.7-55.8-124.7-124.7c0-24.4 7-47.1 19.1-66.4h-36.3C223 389 215.3 418.1 215.3 449c0 99.9 81 180.9 180.9 180.9z"
                    fill="#FFC734"
                    p-id="30411"
                  ></path>
                  <path
                    d="M378.5 554.5c68.9 0 124.7-55.8 124.7-124.7 0-58-39.6-106.8-93.3-120.7-7.6 6.9-15.6 13.4-24 19.5l-29.1 20.9c-12.7 9.1-27.9 14-43.5 14H273c-12.1 19.2-19.1 42-19.1 66.4-0.1 68.8 55.8 124.6 124.6 124.6z"
                    fill="#FFCB34"
                    p-id="30412"
                  ></path>
                  <path
                    d="M740.3 905.9H209.6c-32.7 0-60-25.5-62.2-58.1L118 421.2c-1.2-17.2 4.9-34.3 16.6-46.8 11.8-12.6 28.4-19.8 45.6-19.8h133.1c13.8 0 27.1-4.3 38.3-12.3l29.1-20.9c31.3-22.5 57.7-52.3 76.1-86.2 18.4-33.9 29.2-72.2 31-110.7 1.3-27.7 24.1-49.4 51.8-49.4h4.8c24.4 0 41.3 9.2 58.1 31.6 43.1 53.9 55.8 128.5 37.6 221.7l-2.5 12.8-0.3 0.8c-1.2 2.8-0.9 5.9 0.9 8.5 1.7 2.6 4.5 4 7.5 4H828c22.8 0 48.5 1.5 64.2 19.7 13.4 15.5 17.2 39.8 11.9 76.6-12.1 105.5-33.6 199.8-49.5 260.4-19.8 75.4-39.2 129.9-49.6 150.4l-0.1 0.2c-15.7 28.8-38 44.1-64.6 44.1zM180.2 372.5c-12.4 0-24 5-32.4 14.1s-12.7 20.9-11.8 33.3l29.4 426.6c1.6 23.2 21 41.3 44.3 41.3h530.7c19.9 0 36.2-11.6 48.6-34.5 10-19.7 28.8-72.7 48.2-146.7 15.8-60 37.1-153.5 49-258v-0.3c4.5-30.9 1.9-51.3-7.7-62.4-9.7-11.2-27.2-13.5-50.6-13.5H645.5c-9 0-17.4-4.5-22.5-12-4.9-7.2-5.9-16.3-2.9-24.4l2.2-11.2c17.1-87.9 5.6-157.5-34.1-207.1l-0.2-0.2C574.5 99.4 563 93 544.2 93h-4.8c-18.1 0-32.9 14.2-33.8 32.2-2 41.2-13.5 82.2-33.2 118.5-19.7 36.3-47.9 68.2-81.4 92.2l-29 20.9c-14.3 10.3-31.2 15.7-48.8 15.7h-133z"
                    fill="#FFA820"
                    p-id="30413"
                  ></path>
                  <path
                    d="M536.9 198.5c-9.7-2.4-19.4 3.5-21.8 13.2-15.5 63-62.2 107.6-62.8 108.1-7.2 6.8-7.6 18.2-0.8 25.4 3.5 3.8 8.3 5.7 13.1 5.7 4.4 0 8.8-1.6 12.3-4.9 2.2-2.1 55-52.1 73.1-125.8 2.5-9.6-3.4-19.3-13.1-21.7zM411.9 354.5l-9.5 6.8c-8.1 5.8-9.9 17.1-4.1 25.1 3.2 4.5 8.1 7 13.2 7.4 4.1 0.3 8.3-0.8 11.9-3.4l9.5-6.8c8.1-5.8 9.9-17.1 4.1-25.1-5.8-8-17-9.8-25.1-4z"
                    fill="#FFFFFF"
                    p-id="30414"
                  ></path>
                  <path
                    d="M222 426.3h78.3c13.4 0 24.3 10.9 24.3 24.3v359.6c0 13.4-10.9 24.3-24.3 24.3h-54.5c-12.8 0-23.4-9.9-24.2-22.7l-23.8-359.6c-0.9-14 10.2-25.9 24.2-25.9z"
                    fill="#FFE3B4"
                    p-id="30415"
                  ></path>
                </svg>
              </span>
            </span>
            <span class="descColor"> 点赞 </span>

            <span class="numColor">
              {{ detailInfo["like"] || 0 }}
            </span>
          </div>

          <div class="box-item commonBorder">
            <span>
              <svg
                t="1648192839864"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="13723"
                width="32"
                height="32"
              >
                <path
                  d="M 117.364 943.185 a 34.5426 34.5426 0 0 1 -30.9756 -19.3357 c -8.51405 -17.2242 -1.55726 -38.1549 15.5387 -46.7443 l 218.307 -109.6 c 17.0922 -8.54045 37.8457 -1.55349 46.356 15.6669 c 8.51028 17.2279 1.54972 38.1549 -15.5425 46.7519 l -218.304 109.6 a 34.1995 34.1995 0 0 1 -15.3803 3.66127 Z m 0 0"
                  fill="#fc8874"
                  p-id="13724"
                ></path>
                <path
                  d="M 234.37 738.055 c -51.1154 -59.026 -81.6077 -133.518 -81.6077 -214.447 c 0 -191.696 171.017 -347.107 381.987 -347.107 c 210.98 0 382.004 155.411 382.004 347.107 S 745.726 870.712 534.749 870.712 c -52.3747 0 -102.3 -9.59077 -147.749 -26.9297 c -3.20134 -1.21293 -6.38943 -2.47226 -9.55431 -3.78129 c -0.00331402 0 -114.93 -64.6896 -143.076 -101.946 Z m 0 0"
                  fill="#fc8874"
                  p-id="13725"
                ></path>
                <path
                  d="M 512 887.32 c -60.3223 0 -118.895 -10.6746 -174.093 -31.7373 a 385.632 385.632 0 0 1 -11.3496 -4.49834 c -14.136 -5.84445 -20.8779 -22.1373 -15.0787 -36.3827 c 5.79921 -14.2416 21.9601 -21.0664 36.0923 -15.1956 c 3.28044 1.34611 6.59858 2.6432 9.92426 3.92144 c 48.9049 18.657 100.894 28.1213 154.509 28.1213 c 218.994 0 397.159 -160.658 397.159 -358.122 c 0 -197.471 -178.165 -358.122 -397.159 -358.122 S 114.849 275.956 114.849 473.427 c 0 80.6007 29.0224 156.737 83.949 220.166 c 10.0412 11.5984 8.85718 29.2185 -2.64697 39.3539 a 27.5066 27.5066 0 0 1 -39.0372 -2.66583 C 93.2693 656.524 59.5261 567.711 59.5261 473.423 C 59.5261 245.199 262.506 59.5261 512 59.5261 c 249.502 0 452.474 185.673 452.474 413.897 c 0 228.228 -202.972 413.897 -452.474 413.897 Z m 0 0"
                  fill="#484848"
                  p-id="13726"
                ></path>
                <path
                  d="M 110.441 964.474 a 27.6348 27.6348 0 0 1 -24.7805 -15.4558 c -6.80973 -13.7929 -1.24053 -30.5307 12.4279 -37.3894 l 218.307 -109.593 c 13.6873 -6.85875 30.2818 -1.25184 37.0915 12.526 c 6.80973 13.7929 1.2443 30.5307 -12.4279 37.3932 l -218.304 109.589 a 27.4312 27.4312 0 0 1 -12.3148 2.92977 Z m 197.757 -418.063 c -31.1415 0 -56.4801 -25.5459 -56.4801 -56.9325 c 0 -31.4017 25.3385 -56.9476 56.4801 -56.9476 c 31.1377 0 56.4763 25.5459 56.4763 56.9476 c 0 31.3866 -25.3385 56.9325 -56.4763 56.9325 Z m 0 -79.0208 c -12.0773 0 -21.9035 9.90918 -21.9035 22.0883 c 0 12.1715 9.82245 22.0656 21.9035 22.0656 c 12.0735 0 21.8997 -9.89787 21.8997 -22.0656 c 0 -12.1791 -9.82623 -22.0883 -21.8997 -22.0883 Z M 512 546.411 c -31.134 0 -56.4687 -25.5459 -56.4687 -56.9325 c 0 -31.4017 25.3348 -56.9476 56.4687 -56.9476 c 31.1377 0 56.4763 25.5459 56.4763 56.9476 c 0.00377061 31.3866 -25.3348 56.9325 -56.4763 56.9325 Z m 0 -79.0208 c -12.0735 0 -21.8997 9.90918 -21.8997 22.0883 c 0 12.1715 9.82245 22.0656 21.8997 22.0656 s 21.8997 -9.89787 21.8997 -22.0656 c 0.00377061 -12.1791 -9.82245 -22.0883 -21.8997 -22.0883 Z m 205.472 79.0208 c -31.1453 0 -56.4801 -25.5459 -56.4801 -56.9325 c 0 -31.4017 25.3385 -56.9476 56.4801 -56.9476 s 56.4801 25.5459 56.4801 56.9476 c 0 31.3866 -25.3385 56.9325 -56.4801 56.9325 Z m 0 -79.0208 c -12.0735 0 -21.9073 9.90918 -21.9073 22.0883 c 0 12.1715 9.83 22.0656 21.9073 22.0656 s 21.9073 -9.89787 21.9073 -22.0656 c 0 -12.1791 -9.83377 -22.0883 -21.9073 -22.0883 Z m 0 0"
                  fill="#484848"
                  p-id="13727"
                ></path>
              </svg>
            </span>

            <span class="descColor"> 评论 </span>

            <span class="numColor"> {{ detailInfo["comment"] || 0 }} </span>
          </div>

          <div class="box-item commonBorder">
            <span>
              <svg
                t="1648192867644"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="15547"
                width="32"
                height="32"
              >
                <path
                  d="M902.4 291.2L692.2 128.7c-44-34-107.9-2.6-107.9 53v45c-188.9 1.3-341.9 159.8-341.9 356 0 42.7 7.1 80.7 19 118.7C308.8 563.7 434.7 464 584.2 464v42.7c0 55.6 63.9 87 107.9 53l210.3-162.5c34.7-26.8 34.7-79.2 0-106z"
                  fill="#2867CE"
                  p-id="15548"
                ></path>
                <path
                  d="M768.3 901.9H257.7c-93.1 0-168.5-75.5-168.5-168.5V353.6c0-93.1 75.5-168.5 168.5-168.5h49.6c26.6 0 48.1 21.5 48.1 48.1s-21.5 48.1-48.1 48.1h-49.6c-40 0-72.4 32.4-72.4 72.4v379.8c0 40 32.4 72.4 72.4 72.4h510.5c40 0 72.4-32.4 72.4-72.4v-132c0-26.6 21.5-48.1 48.1-48.1s48.1 21.5 48.1 48.1v132c0 93-75.5 168.4-168.5 168.4z"
                  fill="#BDD2EF"
                  p-id="15549"
                ></path>
              </svg>
            </span>

            <span class="descColor"> 分享 </span>

            <span class="numColor">
              {{ detailInfo["share"] || 0 }}
            </span>
          </div>

          <div v-if="detailInfo['averagecount']" class="box-item commonBorder">
            <span>
              <span>
                <svg
                  t="1657607091686"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  p-id="5924"
                  width="32"
                  height="32"
                >
                  <path
                    d="M541.866667 453.688889c-62.577778 0-113.777778-51.2-113.777778-113.777778s51.2-113.777778 113.777778-113.777778 113.777778 51.2 113.777777 113.777778-49.777778 113.777778-113.777777 113.777778z m0-172.088889c-31.288889 0-56.888889 25.6-56.888889 56.888889s25.6 56.888889 56.888889 56.888889 56.888889-25.6 56.888889-56.888889-24.177778-56.888889-56.888889-56.888889zM541.866667 883.2c-15.644444 0-28.444444-12.8-28.444445-28.444444V509.155556c0-15.644444 12.8-28.444444 28.444445-28.444445s28.444444 12.8 28.444444 28.444445V853.333333c0 17.066667-12.8 29.866667-28.444444 29.866667z"
                    fill="#3FA6AD"
                    p-id="5925"
                  ></path>
                  <path
                    d="M802.133333 611.555556c-15.644444 0-28.444444-12.8-28.444444-28.444445v-99.555555l-224.711111 54.044444c-4.266667 1.422222-8.533333 1.422222-12.8 0L311.466667 483.555556v99.555555c0 15.644444-12.8 28.444444-28.444445 28.444445s-28.444444-12.8-28.444444-28.444445v-135.111111c0-8.533333 4.266667-17.066667 11.377778-22.755556 7.111111-5.688889 15.644444-7.111111 24.177777-5.688888l253.155556 59.733333L796.444444 419.555556c8.533333-1.422222 17.066667 0 24.177778 5.688888s11.377778 14.222222 11.377778 22.755556V583.111111c-1.422222 15.644444-14.222222 28.444444-29.866667 28.444445zM541.866667 883.2c-2.844444 0-4.266667 0-7.111111-1.422222l-260.266667-62.577778c-12.8-2.844444-21.333333-14.222222-21.333333-27.022222v-78.222222c0-15.644444 12.8-28.444444 28.444444-28.444445s28.444444 12.8 28.444444 28.444445v55.466666L541.866667 824.888889l231.822222-55.466667v-55.466666c0-15.644444 12.8-28.444444 28.444444-28.444445s28.444444 12.8 28.444445 28.444445v78.222222c0 12.8-8.533333 24.177778-21.333334 27.022222L548.977778 881.777778c-2.844444 1.422222-4.266667 1.422222-7.111111 1.422222z"
                    fill="#3FA6AD"
                    p-id="5926"
                  ></path>
                  <path
                    d="M294.4 743.822222c-5.688889 0-11.377778 0-17.066667-1.422222-21.333333-4.266667-41.244444-14.222222-58.311111-31.288889-39.822222-39.822222-42.666667-99.555556-8.533333-135.111111 19.911111-19.911111 46.933333-27.022222 76.8-22.755556 21.333333 4.266667 42.666667 14.222222 58.311111 31.288889 39.822222 39.822222 42.666667 99.555556 8.533333 135.111111-15.644444 15.644444-36.977778 24.177778-59.733333 24.177778zM270.222222 610.133333c-5.688889 0-12.8 1.422222-18.488889 7.111111-12.8 12.8-8.533333 36.977778 8.533334 54.044445 9.955556 9.955556 21.333333 14.222222 28.444444 14.222222 7.111111 1.422222 18.488889 1.422222 27.022222-5.688889 12.8-12.8 8.533333-36.977778-8.533333-54.044444-9.955556-9.955556-21.333333-14.222222-28.444444-14.222222-2.844444-1.422222-5.688889-1.422222-8.533334-1.422223zM789.333333 743.822222c-22.755556 0-44.088889-8.533333-59.733333-24.177778-34.133333-34.133333-31.288889-95.288889 8.533333-135.111111 15.644444-15.644444 35.555556-27.022222 58.311111-31.288889 29.866667-5.688889 56.888889 2.844444 76.8 22.755556 34.133333 34.133333 31.288889 95.288889-8.533333 135.111111-15.644444 15.644444-35.555556 27.022222-58.311111 31.288889-5.688889 1.422222-11.377778 1.422222-17.066667 1.422222z m25.6-133.688889h-7.111111c-7.111111 1.422222-18.488889 4.266667-28.444444 14.222223-17.066667 17.066667-21.333333 41.244444-8.533334 54.044444 8.533333 8.533333 19.911111 7.111111 27.022223 5.688889 7.111111-1.422222 18.488889-4.266667 28.444444-14.222222 17.066667-17.066667 21.333333-41.244444 8.533333-54.044445-7.111111-4.266667-14.222222-5.688889-19.911111-5.688889z"
                    fill="#DC4569"
                    p-id="5927"
                  ></path>
                </svg>
              </span>
            </span>

            <span class="descColor"> 人均阅读 </span>
            <span class="numColor">
              {{ detailInfo["averagecount"] || 0 }}
            </span>
          </div>

          <div v-if="detailInfo['averagelike']" class="box-item commonBorder">
            <span>
              <span>
                <svg
                  t="1657607246749"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  p-id="22001"
                  width="32"
                  height="32"
                >
                  <path
                    d="M691.7 134.8c-73.5 0-131.7 37.5-176.4 99-45.1-61.5-102.9-99-176.4-99C206.2 134.8 98 254.1 98 401.3c0 87.8 38.8 149.3 69.8 198.6 89.7 144 306.5 298.6 316 306.1 9.4 7.5 19.9 10.7 31 10.7 11 0 21.5-3.7 31-10.7 9.4-7.5 226.2-162.7 316.5-306.1 31-49.2 69.8-110.8 69.8-198.6-0.1-146.7-108.2-266.5-240.4-266.5z"
                    fill="#FF556E"
                    p-id="22002"
                  ></path>
                  <path
                    d="M261.2 149C166.4 184.8 98 284.3 98 401.3c0 15.3 1.2 29.9 3.3 43.7 7.8-120.9 68.8-227.3 159.9-296zM434.3 868.4c27.9 21.8 46.8 35.4 49.5 37.5 9.4 7.5 19.9 10.7 31 10.7 11 0 21.5-3.7 31-10.7 2.9-2.3 25.3-18.5 57.6-44-31.8 8.1-65.2 12.4-99.6 12.4-23.7 0.1-46.9-2-69.5-5.9zM731.8 138.5C837.6 211.2 907 333 907 471.1c0 19.3-1.4 38.3-4 56.9 16.5-35.5 29-76.9 29-126.7 0-131.5-86.9-241.5-200.2-262.8z"
                    fill="#FF556E"
                    p-id="22003"
                  ></path>
                  <path
                    d="M343.6 134.8c-1.5 0-3.1-0.1-4.6-0.1-27.2 0-53.4 5-77.8 14.3-91.1 68.7-152.1 175.1-159.8 296 10 65.2 40.9 114.2 66.5 154.9 4.9 7.8 10.2 15.7 15.8 23.6-28.6-50.6-45-109-45-171.3-0.1-141.3 84-262.8 204.9-317.4zM731.8 138.5c-13.1-2.5-26.5-3.8-40.1-3.8-17.3 0-33.8 2.1-49.4 6.1 114 57.2 192.3 175.1 192.3 311.4 0 192.2-155.8 348-348 348-80.9 0-155.4-27.6-214.5-74 57.5 57.9 120.1 109.3 162.3 142.2 22.6 3.9 45.8 6 69.4 6 34.4 0 67.7-4.3 99.6-12.4 72.9-57.6 196.3-162.7 258.9-262.1 13.4-21.3 28.2-44.8 40.8-71.8 2.6-18.6 4-37.6 4-56.9-0.1-138.2-69.5-260-175.3-332.7z"
                    fill="#FF5F71"
                    p-id="22004"
                  ></path>
                  <path
                    d="M834.6 452.2c0-136.2-78.3-254.2-192.3-311.3-19.9 5.1-38.4 13.2-55.7 24C690 210.1 762.2 313.2 762.2 433.2c0 161.7-131.1 292.7-292.7 292.7s-292.8-131-292.8-292.7c0-140.6 99.1-258 231.2-286.2-19.9-7.5-41.3-11.7-64.3-12.2-120.9 54.5-205 176.1-205 317.3 0 62.3 16.4 120.7 45 171.3 24.7 34.9 55.8 69.8 88.5 102.7 59.1 46.3 133.6 74 214.5 74 192.2 0.1 348-155.7 348-347.9z"
                    fill="#FF6974"
                    p-id="22005"
                  ></path>
                  <path
                    d="M176.7 433.2c0 161.7 131.1 292.7 292.7 292.7s292.7-131.1 292.7-292.7c0-120-72.2-223.1-175.6-268.3-14 8.8-27.2 19.4-39.5 31.6 84 36.6 142.7 120.3 142.7 217.8 0 131.2-106.3 237.5-237.5 237.5S214.8 545.4 214.8 414.3s106.3-237.5 237.5-237.5c3 0 5.9 0.1 8.8 0.2-16.5-12.7-34.2-22.9-53.2-30-132.1 28.2-231.2 145.6-231.2 286.2z"
                    fill="#FF7377"
                    p-id="22006"
                  ></path>
                  <path
                    d="M214.8 414.3c0 131.2 106.3 237.5 237.5 237.5s237.5-106.3 237.5-237.5c0-97.4-58.7-181.2-142.7-217.8-10.8 10.7-21 22.7-30.6 35.7 59.8 29.9 100.9 91.7 100.9 163.1 0 100.6-81.6 182.2-182.2 182.2S253 495.9 253 395.3s81.6-182.2 182.2-182.2c27.8 0 54.2 6.2 77.8 17.4-15.7-20.9-32.9-38.9-51.8-53.5-2.9-0.1-5.9-0.2-8.8-0.2-131.3 0-237.6 106.3-237.6 237.5z"
                    fill="#FF7E7A"
                    p-id="22007"
                  ></path>
                  <path
                    d="M252.9 395.3c0 100.6 81.6 182.2 182.2 182.2s182.2-81.6 182.2-182.2c0-71.4-41.1-133.2-100.9-163.1-0.4 0.5-0.8 1-1.1 1.5-0.8-1.1-1.6-2.2-2.5-3.3-23.6-11.1-49.9-17.4-77.8-17.4-100.6 0.1-182.1 81.7-182.1 182.3z m292-19c0 70.1-56.8 127-127 127s-127-56.8-127-127 56.8-127 127-127 127 56.9 127 127z"
                    fill="#FF887D"
                    p-id="22008"
                  ></path>
                  <path
                    d="M417.9 376.3m-127 0a127 127 0 1 0 254 0 127 127 0 1 0-254 0Z"
                    fill="#FF9280"
                    p-id="22009"
                  ></path>
                  <path
                    d="M691.7 152.8c29.7 0 58.5 6.5 85.7 19.3 26.4 12.4 50.2 30.3 70.7 53 20.6 22.9 36.8 49.5 48.2 79.1 11.8 30.8 17.8 63.4 17.8 97.1 0 36.5-7.1 71.4-21.8 106.6-13 31.1-30 58.2-45 82.1l-0.2 0.3c-34.4 54.6-91.8 117.7-170.6 187.3-62.4 55.2-117.9 96.5-136.2 110-3 2.2-4.4 3.3-5.3 4-6.4 4.7-13.2 7-20.1 7-7.5 0-14-2.2-19.8-6.8-0.9-0.7-2.3-1.8-5.8-4.3-18.3-13.5-73.9-54.8-136.2-109.9-78.6-69.5-135.8-132.6-170-187.3l-0.2-0.4c-15-23.9-32.1-51-45-82.1-14.7-35.2-21.8-70.1-21.8-106.6 0-137 100-248.5 222.9-248.5 32.3 0 62.5 8.1 89.7 24 26 15.2 50.3 37.9 72.1 67.7l14.6 19.9 14.5-20c21.5-29.6 45.6-52.3 71.8-67.6 27.4-15.9 57.6-23.9 90-23.9m0-18c-73.5 0-131.7 37.5-176.4 99-45.1-61.5-102.9-99-176.4-99C206.2 134.8 98 254.1 98 401.3c0 87.8 38.8 149.3 69.8 198.6 89.7 144 306.5 298.6 316 306.1 9.4 7.5 19.9 10.7 31 10.7 11 0 21.5-3.7 31-10.7 9.4-7.5 226.2-162.7 316.5-306.1 31-49.2 69.8-110.8 69.8-198.6-0.1-146.7-108.2-266.5-240.4-266.5z"
                    fill="#EF4868"
                    p-id="22010"
                  ></path>
                  <path
                    d="M603.4 270c-36.8 0-65.9 19.1-88.2 50.6-22.6-31.4-51.5-50.6-88.2-50.6-66.4 0-120.5 61-120.5 136.1 0 44.8 19.4 76.3 34.9 101.4 44.9 73.5 153.3 152.5 158 156.4 4.7 3.8 10 5.5 15.5 5.5s10.8-1.9 15.5-5.5c4.7-3.8 113.1-83.1 158.3-156.4 15.5-25.2 34.9-56.6 34.9-101.4 0-74.8-54.1-136.1-120.2-136.1z"
                    fill="#FFC7C7"
                    p-id="22011"
                  ></path>
                  <path
                    d="M225 274c-4 0-8-1.3-11.3-4-7.7-6.3-8.9-17.6-2.7-25.3 57.5-71 144.2-62.6 147.9-62.2 9.9 1.1 17 9.9 16 19.8-1.1 9.9-9.9 17-19.8 16-0.6-0.1-18.6-1.7-41.9 3.7-30.6 7-55.5 22.3-74.2 45.4-3.6 4.3-8.8 6.6-14 6.6z"
                    fill="#FFFFFF"
                    p-id="22012"
                  ></path>
                  <path
                    d="M187.4 335.6c-2.4 0-4.8-0.5-7.1-1.5-9.1-3.9-13.4-14.5-9.5-23.6l5.3-12.3c3.9-9.1 14.5-13.4 23.6-9.5s13.4 14.5 9.5 23.6l-5.3 12.3c-2.9 7-9.5 11-16.5 11z"
                    fill="#FFFFFF"
                    p-id="22013"
                  ></path>
                </svg>
              </span>
            </span>
            <span class="descColor"> 人均点赞 </span>

            <span class="numColor">
              {{ detailInfo["averagelike"] || 0 }}
            </span>
          </div>

          <div
            v-if="detailInfo['averagecomment']"
            class="box-item commonBorder"
          >
            <span>
              <svg
                t="1657607412169"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="37309"
                width="32"
                height="32"
              >
                <path
                  d="M779.52 464.64c-16.9472 0-30.72-13.7728-30.72-30.72V252.7744l-61.5936 55.8592a30.60224 30.60224 0 0 1-23.1424 7.8336 30.73024 30.73024 0 0 1-21.5552-11.52l-123.6992-156.0576-119.3984 155.6992a30.8736 30.8736 0 0 1-21.2992 11.8784c-8.448 0.8704-16.8448-1.8432-23.2448-7.3728L288 250.9312v183.04c0 16.9472-13.7728 30.72-30.72 30.72s-30.72-13.7728-30.72-30.72v-204.288c0-20.5312 11.6224-38.6048 30.3104-47.104C275.5584 174.08 296.8064 177.152 312.32 190.6176l58.2656 50.6368L477.0304 102.4c9.6256-13.056 24.9856-20.8896 41.2672-21.0432h0.4096c16.0768 0 31.3856 7.5776 41.1648 20.3776l110.3872 139.3152 53.4528-48.4352c15.1552-13.7216 37.0176-17.2544 55.7568-8.96 18.688 8.2944 30.7712 26.88 30.7712 47.3088v202.9568c0 16.9984-13.7728 30.72-30.72 30.72z"
                  fill="#e05306"
                  p-id="37310"
                ></path>
                <path
                  d="M518.4 723.3536c-160.9216 0-291.84-130.9184-291.84-291.84 0-16.9472 13.7728-30.72 30.72-30.72s30.72 13.7728 30.72 30.72c0 127.0272 103.3728 230.4 230.4 230.4s230.4-103.3728 230.4-230.4c0-16.9472 13.7728-30.72 30.72-30.72s30.72 13.7728 30.72 30.72c0 160.9216-130.9184 291.84-291.84 291.84zM416.2048 915.3536c-16.9472 0-30.72-13.7728-30.72-30.72 0-72.0384-58.624-130.6624-130.6624-130.6624-46.4896 0-89.9072 25.0368-113.2032 65.3312a30.72 30.72 0 0 1-41.984 11.2128 30.72 30.72 0 0 1-11.2128-41.984c34.2528-59.2384 98.048-96 166.4-96 105.9328 0 192.1024 86.1696 192.1024 192.1024 0 16.9472-13.7216 30.72-30.72 30.72zM615.68 915.3536c-16.9472 0-30.72-13.7728-30.72-30.72 0-105.9328 86.1696-192.1024 192.1024-192.1024 68.352 0 132.096 36.8128 166.4 96a30.72 30.72 0 0 1-11.2128 41.984 30.72 30.72 0 0 1-41.984-11.2128c-23.296-40.2944-66.7136-65.3312-113.2032-65.3312-72.0384 0-130.6624 58.624-130.6624 130.6624 0 16.9472-13.7728 30.72-30.72 30.72z"
                  fill="#e05306"
                  p-id="37311"
                ></path>
                <path
                  d="M518.4 951.04c-16.9472 0-30.72-13.7728-30.72-30.72v-215.04c0-16.9472 13.7728-30.72 30.72-30.72s30.72 13.7728 30.72 30.72v215.04c0 16.9984-13.7728 30.72-30.72 30.72z"
                  fill="#e05306"
                  p-id="37312"
                ></path>
              </svg>
            </span>

            <span class="descColor"> 人均评论 </span>

            <span class="numColor">
              {{ detailInfo["averagecomment"] || 0 }}
            </span>
          </div>

          <div v-if="detailInfo['averageshare']" class="box-item commonBorder">
            <span>
              <svg
                t="1657607525832"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="50723"
                width="32"
                height="32"
              >
                <path
                  d="M754.2 374.1c14.7 36.6 22.9 76.5 22.9 118.3 0 175.5-142.3 317.9-317.9 317.9-28.3 0-55.7-3.7-81.9-10.7 42.9 25.1 92.7 39.5 146 39.5 160.1 0 289.8-129.8 289.8-289.8 0.1-65.9-21.9-126.6-58.9-175.2z"
                  fill="#F8B62D"
                  p-id="50724"
                ></path>
                <path
                  d="M777.1 492.4c0-41.8-8.2-81.8-22.9-118.3-52.9-69.7-136.6-114.7-230.9-114.7-160.1 0-289.8 129.8-289.8 289.8 0 106.8 57.8 200.1 143.8 250.3 26.1 6.9 53.5 10.7 81.9 10.7 175.6 0.1 317.9-142.2 317.9-317.8z"
                  fill="#FCEE21"
                  p-id="50725"
                ></path>
                <path
                  d="M509.3 849.2c-173.1 0-313.9-140.8-313.9-313.9 0-47.8 10.5-93.7 31.1-136.5 2.4-5 8.5-7.1 13.5-4.7 5 2.4 7.1 8.5 4.7 13.5-19.3 40-29.1 82.9-29.1 127.7 0 162 131.8 293.8 293.8 293.8s293.8-131.8 293.8-293.8-131.9-293.8-293.9-293.8c-70.5 0-138.7 25.4-192 71.5-4.2 3.6-10.6 3.2-14.2-1-3.6-4.2-3.2-10.6 1-14.2 57-49.2 129.8-76.4 205.2-76.4 173.1 0 313.9 140.8 313.9 313.9S682.4 849.2 509.3 849.2z"
                  fill="#3E3A39"
                  p-id="50726"
                ></path>
                <path
                  d="M703.3 226.6c-15.1 0-27.3-12.3-27.3-27.3 0-15.1 12.3-27.3 27.3-27.3 15.1 0 27.3 12.3 27.3 27.3s-12.2 27.3-27.3 27.3z m0-41.3c-7.7 0-13.9 6.2-13.9 13.9s6.2 13.9 13.9 13.9 13.9-6.2 13.9-13.9c0-7.6-6.3-13.9-13.9-13.9z"
                  fill="#47B7F8"
                  p-id="50727"
                ></path>
                <path
                  d="M260.2 270.2h-12v-12c0-3.2-2.6-5.8-5.8-5.8-3.2 0-5.8 2.6-5.8 5.8v12h-12c-3.2 0-5.8 2.6-5.8 5.8s2.6 5.8 5.8 5.8h12v12c0 3.2 2.6 5.8 5.8 5.8 3.2 0 5.8-2.6 5.8-5.8v-12h12c3.2 0 5.8-2.6 5.8-5.8s-2.6-5.8-5.8-5.8zM864.4 386.4h-12v-12c0-3.2-2.6-5.8-5.8-5.8-3.2 0-5.8 2.6-5.8 5.8v12h-12c-3.2 0-5.8 2.6-5.8 5.8s2.6 5.8 5.8 5.8h12v12c0 3.2 2.6 5.8 5.8 5.8 3.2 0 5.8-2.6 5.8-5.8v-12h12c3.2 0 5.8-2.6 5.8-5.8s-2.6-5.8-5.8-5.8z"
                  fill="#F7E42F"
                  p-id="50728"
                ></path>
                <path
                  d="M123.6 543.9h-13.9V530a6.7 6.7 0 0 0-13.4 0v13.9H82.4a6.7 6.7 0 0 0 0 13.4h13.9v13.9a6.7 6.7 0 0 0 13.4 0v-13.9h13.9c3.7 0 6.7-3 6.7-6.7 0.1-3.7-2.9-6.7-6.7-6.7z"
                  fill="#F8B62D"
                  p-id="50729"
                ></path>
                <path
                  d="M351.3 209.3m-10.1 0a10.1 10.1 0 1 0 20.2 0 10.1 10.1 0 1 0-20.2 0Z"
                  fill="#F8B62D"
                  p-id="50730"
                ></path>
                <path
                  d="M802 262.5m-13.4 0a13.4 13.4 0 1 0 26.8 0 13.4 13.4 0 1 0-26.8 0Z"
                  fill="#F8B62D"
                  p-id="50731"
                ></path>
                <path
                  d="M123.7 209.3m-13.4 0a13.4 13.4 0 1 0 26.8 0 13.4 13.4 0 1 0-26.8 0Z"
                  fill="#F7E42F"
                  p-id="50732"
                ></path>
                <path
                  d="M136.7 392.2c-15.1 0-27.3-12.3-27.3-27.3 0-15.1 12.3-27.3 27.3-27.3s27.3 12.3 27.3 27.3-12.3 27.3-27.3 27.3z m0-41.3c-7.7 0-13.9 6.2-13.9 13.9s6.2 13.9 13.9 13.9 13.9-6.2 13.9-13.9c-0.1-7.6-6.3-13.9-13.9-13.9z"
                  fill="#F8B62D"
                  p-id="50733"
                ></path>
                <path
                  d="M465.7 98.6c-1.5-1.4-3.8-1.4-5.3 0-0.7 0.7-1.1 1.6-1.1 2.6s0.4 1.9 1.1 2.6l11.9 11.9c0.7 0.7 1.6 1.1 2.6 1.1s1.9-0.4 2.6-1.1c1.4-1.5 1.4-3.8 0-5.3l-11.8-11.8zM497.2 130.1c-0.7-0.7-1.6-1.1-2.6-1.1s-1.9 0.4-2.6 1.1c-1.4 1.5-1.4 3.8 0 5.3l11.9 11.9c0.7 0.7 1.6 1.1 2.6 1.1s1.9-0.4 2.6-1.1c0.7-0.7 1.1-1.6 1.1-2.6s-0.4-1.9-1.1-2.6l-11.9-12zM494.6 116.9c1 0 1.9-0.4 2.6-1.1l11.9-11.9c0.7-0.7 1.1-1.6 1.1-2.6s-0.4-1.9-1.1-2.6c-1.5-1.5-3.8-1.5-5.3 0L492 110.5c-1.4 1.5-1.4 3.8 0 5.3 0.7 0.7 1.6 1.1 2.6 1.1zM472.4 130.1L460.5 142c-0.7 0.7-1.1 1.6-1.1 2.6s0.4 1.9 1.1 2.6c0.7 0.7 1.6 1.1 2.6 1.1s1.9-0.4 2.6-1.1l11.9-11.9c1.4-1.5 1.4-3.8 0-5.3-1.4-1.3-3.8-1.3-5.2 0.1zM474.7 123c0-2.1-1.7-3.7-3.7-3.7h-16.9c-2.1 0-3.7 1.7-3.7 3.7 0 2.1 1.7 3.7 3.7 3.7H471c2 0 3.7-1.7 3.7-3.7zM515.5 119.2h-16.9c-2.1 0-3.7 1.7-3.7 3.7 0 2.1 1.7 3.7 3.7 3.7h16.9c2.1 0 3.7-1.7 3.7-3.7 0.1-2-1.6-3.7-3.7-3.7zM484.8 88.5c-2.1 0-3.7 1.7-3.7 3.7v16.9c0 2.1 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7V92.2c0-2-1.6-3.7-3.7-3.7zM484.8 133.1c-2.1 0-3.7 1.7-3.7 3.7v16.9c0 2.1 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7v-16.9c0-2-1.6-3.7-3.7-3.7z"
                  fill="#FAEE00"
                  p-id="50734"
                ></path>
                <path
                  d="M917.1 517.2c-1.5-1.4-3.8-1.4-5.3 0-0.7 0.7-1.1 1.6-1.1 2.6s0.4 1.9 1.1 2.6l11.9 11.9c0.7 0.7 1.6 1.1 2.6 1.1s1.9-0.4 2.6-1.1c1.4-1.5 1.4-3.8 0-5.3l-11.8-11.8zM948.7 548.7c-0.7-0.7-1.6-1.1-2.6-1.1s-1.9 0.4-2.6 1.1c-1.4 1.5-1.4 3.8 0 5.3l11.9 11.9c0.7 0.7 1.6 1.1 2.6 1.1s1.9-0.4 2.6-1.1c0.7-0.7 1.1-1.6 1.1-2.6s-0.4-1.9-1.1-2.6l-11.9-12zM946 535.5c1 0 1.9-0.4 2.6-1.1l11.9-11.9c0.7-0.7 1.1-1.6 1.1-2.6s-0.4-1.9-1.1-2.6c-1.5-1.5-3.8-1.5-5.3 0l-11.9 11.9c-1.4 1.5-1.4 3.8 0 5.3 0.8 0.6 1.7 1 2.7 1zM923.8 548.7l-11.9 11.9c-0.7 0.7-1.1 1.6-1.1 2.6s0.4 1.9 1.1 2.6c0.7 0.7 1.6 1.1 2.6 1.1s1.9-0.4 2.6-1.1l11.9-11.9c1.4-1.5 1.4-3.8 0-5.3-1.3-1.3-3.8-1.3-5.2 0.1zM926.1 541.6c0-2.1-1.7-3.7-3.7-3.7h-16.9c-2.1 0-3.7 1.7-3.7 3.7s1.7 3.7 3.7 3.7h16.9c2 0 3.7-1.7 3.7-3.7zM967 537.9h-16.9c-2.1 0-3.7 1.7-3.7 3.7s1.7 3.7 3.7 3.7H967c2.1 0 3.7-1.7 3.7-3.7s-1.7-3.7-3.7-3.7zM936.2 507.1c-2.1 0-3.7 1.7-3.7 3.7v16.9c0 2.1 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7v-16.9c0-2-1.6-3.7-3.7-3.7zM936.2 551.7c-2.1 0-3.7 1.7-3.7 3.7v16.9c0 2.1 1.7 3.7 3.7 3.7s3.7-1.7 3.7-3.7v-16.9c0-2-1.6-3.7-3.7-3.7z"
                  fill="#F8B62D"
                  p-id="50735"
                ></path>
                <path
                  d="M845.2 850.3h-24c-5.6 0-10.1-4.5-10.1-10.1s4.5-10.1 10.1-10.1h24c5.6 0 10.1 4.5 10.1 10.1s-4.6 10.1-10.1 10.1zM203.4 850.3h-30.7c-5.6 0-10.1-4.5-10.1-10.1s4.5-10.1 10.1-10.1h30.7c5.6 0 10.1 4.5 10.1 10.1s-4.5 10.1-10.1 10.1zM791.5 850.3H237.3c-5.6 0-10.1-4.5-10.1-10.1s4.5-10.1 10.1-10.1h554.1c5.6 0 10.1 4.5 10.1 10.1s-4.5 10.1-10 10.1z"
                  fill="#3E3A39"
                  p-id="50736"
                ></path>
                <path
                  d="M376.9 327.6c-7.8 3.8-15 9.1-21 15.4-4.2 4.5-4.8 12.3 0 16.6 4.6 4.2 12.1 4.8 16.6 0 1.3-1.3 2.5-2.6 3.9-3.8 0.6-0.5 1.2-1.1 1.8-1.6 1.7-1.4-2.1 1.4 1-0.8 3-2.1 6.1-3.9 9.5-5.5 5.6-2.7 7.5-11.1 4.2-16.1-3.6-5.7-10-7-16-4.2zM331.7 369.5c-17.4 15.9-30.1 35.4-38.1 57.5-5.2 14.3 17.6 20.4 22.7 6.3 6.6-18.3 17.6-34.1 32-47.1 11.2-10.2-5.4-26.9-16.6-16.7z"
                  fill="#FFFFFF"
                  p-id="50737"
                ></path>
                <path
                  d="M255.5 372c-2.2 0-4.4-0.7-6.2-2.2-4.4-3.5-5.1-9.8-1.7-14.2l24.6-31.1c3.4-4.4 9.8-5.1 14.2-1.7 4.4 3.5 5.1 9.8 1.7 14.2l-24.6 31.1c-2.1 2.6-5 3.9-8 3.9z"
                  fill="#3E3A39"
                  p-id="50738"
                ></path>
                <path
                  d="M578.6 444.6m-33.3 0a33.3 33.3 0 1 0 66.6 0 33.3 33.3 0 1 0-66.6 0Z"
                  fill="#FFFFFF"
                  p-id="50739"
                ></path>
                <path
                  d="M578.8 648.9m-33.1 0a33.1 33.1 0 1 0 66.2 0 33.1 33.1 0 1 0-66.2 0Z"
                  fill="#FFFFFF"
                  p-id="50740"
                ></path>
                <path
                  d="M421.8 546.3m-32.3 0a32.3 32.3 0 1 0 64.6 0 32.3 32.3 0 1 0-64.6 0Z"
                  fill="#FFFFFF"
                  p-id="50741"
                ></path>
                <path
                  d="M448.4 521.4c-3.3 0-6.5-1.6-8.4-4.6-3.1-4.7-1.8-10.9 2.9-14l87.5-57.4c4.7-3.1 10.9-1.8 14 2.9 3.1 4.7 1.8 10.9-2.9 14L454 519.7c-1.8 1.2-3.7 1.7-5.6 1.7z"
                  fill="#3E3A39"
                  p-id="50742"
                ></path>
                <path
                  d="M532.9 624.7c-1.7 0-3.5-0.4-5.1-1.4L443.3 574c-4.8-2.8-6.4-9-3.6-13.8 2.8-4.8 9-6.4 13.8-3.6l84.5 49.3c4.8 2.8 6.4 9 3.6 13.8-1.9 3.2-5.3 5-8.7 5z"
                  fill="#3E3A39"
                  p-id="50743"
                ></path>
                <path
                  d="M572.9 487.1c-28.8 0-52.3-23.5-52.3-52.3s23.5-52.3 52.3-52.3 52.3 23.5 52.3 52.3-23.5 52.3-52.3 52.3z m0-84.4c-17.7 0-32.1 14.4-32.1 32.1s14.4 32.1 32.1 32.1 32.1-14.4 32.1-32.1-14.4-32.1-32.1-32.1zM572.9 693.2c-28.8 0-52.3-23.5-52.3-52.3 0-28.8 23.5-52.3 52.3-52.3s52.3 23.5 52.3 52.3c0 28.9-23.5 52.3-52.3 52.3z m0-84.4c-17.7 0-32.1 14.4-32.1 32.1 0 17.7 14.4 32.1 32.1 32.1s32.1-14.4 32.1-32.1c0-17.7-14.4-32.1-32.1-32.1zM415 590.6c-28.8 0-52.3-23.5-52.3-52.3 0-28.8 23.5-52.3 52.3-52.3 28.8 0 52.3 23.5 52.3 52.3 0 28.8-23.4 52.3-52.3 52.3z m0-84.4c-17.7 0-32.1 14.4-32.1 32.1 0 17.7 14.4 32.1 32.1 32.1 17.7 0 32.1-14.4 32.1-32.1 0-17.7-14.4-32.1-32.1-32.1z"
                  fill="#3E3A39"
                  p-id="50744"
                ></path>
              </svg>
            </span>

            <span class="descColor"> 人均分享 </span>

            <span class="numColor">
              {{ detailInfo["averageshare"] || 0 }}
            </span>
          </div>

          <div v-if="detailInfo['conentNumLen']" class="box-item commonBorder">
            <span>
              <svg
                t="1649390924619"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="2364"
                width="32"
                height="32"
              >
                <path
                  d="M757.8112 950.3232H271.5648c-107.264 0-194.2016-86.9376-194.2016-194.2016V269.824c0-107.264 86.9376-194.2016 194.2016-194.2016h486.2464c107.264 0 194.2016 86.9376 194.2016 194.2016v486.2464c0 107.264-86.9376 194.2528-194.2016 194.2528z"
                  fill="#F78748"
                  p-id="2365"
                ></path>
                <path
                  d="M790.2208 514.1504c-14.1312 0-25.6 11.4688-25.6 25.6v172.5952c0 46.1824-37.5808 83.7632-83.7632 83.7632h-322.048c-46.1824 0-83.7632-37.5808-83.7632-83.7632V382.3616c0-46.1824 37.5808-83.7632 83.7632-83.7632h7.8848c10.24 45.312 50.7392 79.2576 99.072 79.2576h108.0832c48.3328 0 88.832-33.9456 99.072-79.2576h7.8848c46.1824 0 83.7632 37.5808 83.7632 83.7632 0 14.1312 11.4688 25.6 25.6 25.6s25.6-11.4688 25.6-25.6c0-74.3936-60.5184-134.9632-134.9632-134.9632h-9.5744c-12.4416-42.0352-51.4048-72.8064-97.4336-72.8064h-108.032c-46.0288 0-84.9408 30.7712-97.4336 72.8064h-9.5744c-74.3936 0-134.9632 60.5184-134.9632 134.9632v330.0352c0 74.3936 60.5184 134.9632 134.9632 134.9632h322.048c74.3936 0 134.9632-60.5184 134.9632-134.9632v-172.5952a25.5488 25.5488 0 0 0-25.5488-25.6512zM465.7664 225.792h108.0832c27.8016 0 50.432 22.6304 50.432 50.432s-22.6304 50.432-50.432 50.432H465.7664c-27.8016 0-50.432-22.6304-50.432-50.432s22.6304-50.432 50.432-50.432z"
                  fill="#F7F8F8"
                  p-id="2366"
                ></path>
                <path
                  d="M615.2704 468.5824H424.3968c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6h190.8736c14.1312 0 25.6-11.4688 25.6-25.6s-11.4688-25.6-25.6-25.6zM615.2704 613.2224H424.3968c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6h190.8736c14.1312 0 25.6-11.4688 25.6-25.6s-11.4688-25.6-25.6-25.6z"
                  fill="#F7F8F8"
                  p-id="2367"
                ></path>
              </svg>
            </span>

            <span class="descColor"> 发文 </span>

            <span class="numColor">
              {{ detailInfo["conentNumLen"] }}
            </span>
          </div>
        </div>
      </div>

      <div slot="footer">
        <Button size="large" long @click="showDetailStatus = false"
          >确定</Button
        >
      </div>
    </Modal>

    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div>
      <desc-com :startDate="startDate" :endDate="endDate"></desc-com>

      <list-rank-title
        :rankCycle="rankCycle"
        :defaultRankCycle="defaultRankCycle"
        @chooseRankType="chooseRankType"
      ></list-rank-title>

      <dept-tab-com
        v-if="defaultRankCycle.indexOf('文章') == -1"
        @chooseTab="chooseTabItem"
      ></dept-tab-com>

      <list-com
        @chooseListItem="chooseListItem"
        :columns="columns.slice(1)"
        :showList="showList"
        :titleSelfLine="titleSelfLine"
        :titleSelfTitle="titleSelfTitle"
        :descWidth="descWidth"
        :showLoad="showLoad"
        :comHeight="comHeight"
      ></list-com>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
import listCom from "../../components/listCom.vue";

import { getDeptLevelData } from "../../api/cacheDept";
import { wxConPost } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listRankTitle from "../../components/listRankTitle.vue";
import deptTabCom from "../../components/deptTabCom.vue";
import descCom from "../../components/descCom.vue";

export default {
  name: "jifenRank",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,

    listRankTitle,
    descCom,
    deptTabCom,
  },

  data() {
    return {
      comHeight: 7,
      active: "",
      curTabItem: {},

      title: "单位",
      titleSelfTitle: "title",
      titleSelfLine: false,
      descWidth: false,

      isShowSearch: false, // 是否显示搜索框

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 2, // 部门层级

      showDetailStatus: false,
      detailInfo: {}, // 单位详情

      deptData: [],
      dataList: [],
      showList: [],
      deptInfo: {},
      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      dataNumOption: {},

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      startDate: "",
      endDate: "",
      tongjiType: "集团周榜",
      rankCycle: [
        "集团周榜",
        "集团月榜",
        "单位周榜",
        "单位月榜",
        "文章周榜",
        "文章月榜",
      ],
      defaultRankCycle: "集团周榜",
      showRankStatus: false,

      columns: [
        {
          name: "单位",
          value: "deptName",
        },
        {
          name: "阅读",
          sort: true,
          value: "count",
        },
        {
          name: "点赞",
          sort: true,
          value: "like",
        },
        {
          name: "评论",
          sort: true,
          value: "comment",
        },
        {
          name: "分享",
          sort: true,
          value: "share",
        },
      ],

      sortList: [
        {
          name: "阅读",
          value: "count",
        },
        {
          name: "点赞",
          value: "like",
        },
        {
          name: "评论",
          value: "comment",
        },
        {
          name: "分享",
          value: "share",
        },
      ],
    };
  },
  methods: {
    getRankParam(type) {
      switch (type) {
        case "集团周榜":
          return "lj_week";
        case "集团月榜":
          return "lj_month";
        case "单位周榜":
          return "zd_week";
        case "单位月榜":
          return "zd_month";
        case "文章周榜":
          return "content_week";
        case "文章月榜":
          return "content_month";
        default:
          return "lj_week";
      }
    },

    chooseTabItem(tabItem) {
      this.curTabItem = tabItem;
      // 查找部门为 childDeptList
      this.initRankGroup(tabItem);
    },
    chooseRankType(type) {
      this.titleSelfTitle = "";
      this.titleSelfLine = false;
      this.descWidth = false;
      this.defaultRankCycle = type;
      this.tongjiType = this.getRankParam(type);
      this.getDeptStatus(this.tongjiType);
    },
    initRankGroup(tabItem) {
      // console.log("initRankGroup------", tabItem);
      if (!_.isEmpty(tabItem) && tabItem) {
        const dataList = [];
        for (const iterator of this.dataList) {
          if (_.indexOf(tabItem.childDeptList, iterator.deptId) > -1) {
            dataList.push(iterator);
          }
        }
        this.showList = dataList;
      } else {
        this.showList = this.dataList;
      }
    },
    async getDeptStatus(type) {
      try {
        this.showLoad = true;
        let _type = type || this.getRankParam();
        const _url = `/services/gttt-wxstats-mgt/rankContent/${_type}`;

        const ret = await wxConPost(_url);
        if (ret.errMsg) {
          this.dailogshow = true;
          this.dailogoptions.content = ret.errMsg;
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }
        this.showLoad = false;

        let deptData = _.get(ret, ["ret", "data"], []);
        let timeArea = _.get(ret, ["ret", "timeArea"], "");

        this.dealDateData(timeArea);

        if (_type.indexOf("zd") > -1) {
          this.dealZdRankData(deptData);
        } else if (_type.indexOf("content") > -1) {
          this.dealContentRankData(deptData);
        } else {
          this.dealDeptRankData(deptData);
        }
        // 非文章榜单 处理数据
        if (_type.indexOf("content") == -1) {
          this.initRankGroup(this.curTabItem);
        }
      } catch (error) {
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }
    },
    // 处理部门数据
    dealDeptRankData(data) {
      this.columns = [
        {
          name: "单位",
          value: "deptName",
        },
        {
          name: "单位阅读",
          value: "count",
          sort: true,
        },
        {
          name: "单位点赞",
          value: "like",
          sort: true,
        },
        {
          name: "单位评论",
          value: "comment",
          sort: true,
        },
        {
          name: "单位分享",
          value: "share",
          sort: true,
        },
        {
          name: "人均阅读",
          value: "averagecount",
          sort: true,
        },

        {
          name: "人均点赞",
          value: "averagelike",
          sort: true,
        },
        {
          name: "人均评论",
          value: "averagecomment",
          sort: true,
        },
        {
          name: "人均分享",
          value: "averageshare",
          sort: true,
        },
      ];

      const retData = [];
      for (const iterator of data) {
        let averagecount = 0;
        let averagelike = 0;
        let averageshare = 0;
        let averagecomment = 0;

        if (iterator["userStatusCount"]) {
          averagecount = (iterator["count"] || 0) / iterator["userStatusCount"];
          averagelike = (iterator["like"] || 0) / iterator["userStatusCount"];
          averageshare = (iterator["share"] || 0) / iterator["userStatusCount"];
          averagecomment =
            (iterator["comment"] || 0) / iterator["userStatusCount"];
        }
        retData.push(
          Object.assign(iterator, {
            count: iterator["count"] || 0,
            like: iterator["like"] || 0,
            conentNumLen: iterator["conentNumLen"] || 0,
            comment: iterator["comment"] || 0,
            share: iterator["share"] || 0,

            // 人均阅读量、人均点赞、人均分享量、人均评论量
            人均阅读量: averagecount,
            averagecount: averagecount ? averagecount.toFixed(2) : 0,
            averagelike: averagelike ? averagelike.toFixed(2) : 0,
            averageshare: averageshare ? averageshare.toFixed(2) : 0,
            averagecomment: averagecomment ? averagecomment.toFixed(2) : 0,
          })
        );
      }

      this.dataList = _.reverse(_.sortBy(retData, ["人均阅读量"]));
      // console.log("----", this.dataList);
    },

    // 处理站段数据
    dealZdRankData(data) {
      //  comment: 4
      // conentNumLen: 29
      // count: 102
      // deptId: 6876
      // deptName: "设备监造部"
      // leaderCount: 26
      // like: 4
      // originalNum: 29
      // politicalCount: 83
      // sendContentDay: 6
      // share: 0
      // shujiCount: 0
      // userCount: 48
      // userStatusCount: 47
      // _id: 6876

      this.columns = [
        {
          name: "单位",
          value: "deptName",
        },
        {
          name: "单位阅读",
          value: "count",
          sort: true,
        },
        {
          name: "单位点赞",
          value: "like",
          sort: true,
        },
        {
          name: "单位评论",
          value: "comment",
          sort: true,
        },
        {
          name: "单位分享",
          value: "share",
          sort: true,
        },
        {
          name: "人均阅读",
          value: "averagecount",
          sort: true,
        },
        {
          name: "人均点赞",
          value: "averagecomment",
          sort: true,
        },
        {
          name: "人均评论",
          value: "averagecomment",
          sort: true,
        },
        {
          name: "人均分享",
          value: "averageshare",
          sort: true,
        },
        // {
        //   name: "发文",
        //   sort: true,
        //   value: "conentNumLen",
        // },
      ];
      const retData = [];
      for (const iterator of data) {
        let averagecount = 0;
        let averagelike = 0;
        let averageshare = 0;
        let averagecomment = 0;

        if (iterator["userStatusCount"]) {
          averagecount = (iterator["count"] || 0) / iterator["userStatusCount"];
          averagelike = (iterator["like"] || 0) / iterator["userStatusCount"];
          averageshare = (iterator["share"] || 0) / iterator["userStatusCount"];
          averagecomment =
            (iterator["comment"] || 0) / iterator["userStatusCount"];
        }
        retData.push(
          Object.assign(iterator, {
            count: iterator["count"] || 0,
            like: iterator["like"] || 0,
            share: iterator["share"] || 0,
            comment: iterator["comment"] || 0,
            conentNumLen: iterator["conentNumLen"] || 0,
            // 人均阅读量、人均点赞、人均分享量、人均评论量
            人均阅读量: averagecount,
            averagecount: averagecount ? averagecount.toFixed(2) : 0,
            averagelike: averagelike ? averagelike.toFixed(2) : 0,
            averageshare: averageshare ? averageshare.toFixed(2) : 0,
            averagecomment: averagecomment ? averagecomment.toFixed(2) : 0,
          })
        );
      }
      this.dataList = _.reverse(_.sortBy(retData, ["人均阅读量"]));
      // console.log("----", this.dataList);
    },

    // 处理站段数据
    dealContentRankData(data) {
      try {
        this.titleSelfTitle = "title";
        this.titleSelfLine = true;
        this.descWidth = true;
        this.columns = [
          // {
          //   name: "文章",
          //   value: "title",
          //   selfLine: true,
          // },
          { name: "单位", value: "deptName" },
          {
            name: "总阅读量",
            sort: true,
            value: "count",
          },

          {
            name: "总点赞量",
            sort: true,
            value: "like",
          },

          {
            name: "总评论量",
            sort: true,
            value: "comment",
          },
          {
            name: "总分享量",
            sort: true,
            value: "share",
          },
          {
            name: "阅读率",
            sort: true,
            value: "countRate", // 总阅读量 / 推文总数 / 注册人数
          },
          {
            name: "外部阅读",
            sort: true,
            color: "#666",
            value: "outCount",
          },
        ];
        // console.log("------", data);
        const retData = [];
        for (const iterator of data) {
          // 总阅读量 / 推文总数 / 注册人数
          let userStatusCount = 0;
          let deptInfo = null;
          if (this.deptInfo[iterator.deptId3_content]) {
            userStatusCount =
              this.deptInfo[iterator.deptId3_content]["userStatusCount"];
            deptInfo = this.deptInfo[iterator.deptId3_content];
          }

          let countRate = 0;
          if (userStatusCount > 0) {
            countRate = ((iterator["inPerCount"] || 0) / userStatusCount) * 100;
          }

          let count = (iterator.inCount || 0) + (iterator.outCount || 0);
          let like = (iterator.inLike || 0) + (iterator.outLike || 0);
          let comment = (iterator.inComment || 0) + (iterator.outComment || 0);
          let share = (iterator.inShare || 0) + (iterator.outShare || 0);

          retData.push(
            Object.assign(iterator, {
              单位: deptInfo.dept_2nd_name,
              countRate: `${countRate.toFixed(2)}%`,
              阅读率: countRate,
              count,
              like,
              comment,
              share,
              userStatusCount,
            })
          );
        }
        this.showList = _.reverse(_.sortBy(retData, ["count"]));
      } catch (error) {
        console.log("error----", error);
      }
    },

    //  "2022-03-21_2022-03-23"
    dealDateData(timeArea) {
      this.startDate = timeArea.slice(0, 10);
      this.endDate = timeArea.slice(11, 21);
    },

    chooseListItem(data) {
      // console.log("chooseListItem----", data, this.tongjiType);
      // if (this.tongjiType.indexOf("content") > -1) {
      this.showDetailStatus = true;
      this.detailInfo = data;
      // }
    },

    // 数据排序
    sortData(data) {
      console.log("data----", data);
      if (data && data.value) {
        if (data.order == "aes") {
          // 升序
          this.showList = _.sortBy(this.showList, [data.value]);
        } else {
          // 降序
          this.showList = _.reverse(_.sortBy(this.showList, [data.value]));
        }

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },
  },
  async created() {
    this.getDeptStatus();
    const { deptLevelAnd3Obj } = await getDeptLevelData();
    this.deptInfo = deptLevelAnd3Obj;
  },
};
</script>

<style scoped>
.deptMainTop {
  text-align: center;
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}
</style>
