<template>
  <div>
    <div class="listRankTitle">
      <div
        v-for="(item, key) in rankCycle"
        :key="key"
        @click="chooseRankType(item)"
        :class="item == curRankCycle ? 'banganStyAcvive' : ''"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

export default {
  name: "listCom",
  components: {},
  data() {
    return {
      curRankCycle: "",
    };
  },
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columnsRank: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //榜单周期
    rankCycle: {
      type: Array,
      default: () => {
        return ["周榜", "月榜"];
      },
    },

    // 默认榜单周期
    defaultRankCycle: {
      type: String,
      default: "周榜",
    },
  },
  methods: {
    // 更换榜单
    chooseRankType(type) {
      this.curRankCycle = type;
      this.$emit("chooseRankType", type);
    },
  },
  async created() {
    if (this.defaultRankCycle) {
      this.curRankCycle = this.defaultRankCycle;
    }
  },
};
</script>

<style scoped>
.listRankTitle {
  text-align: center;
  font-size: 23px;
  color: #fff;
  font-weight: 500;
  display: flex;
  /* height: 35px; */
  flex-wrap: wrap;
  line-height: 35px;
  font-size: 14px;
  border-radius: 4px;
  justify-content: center;
  margin: 10px 0;
}

.listRankTitle .banganStyAcvive {
  /* background: rgb(51, 124, 153); */
  /* background: #6f757f; */
  background: #386ba1;
  font-weight: bold;
  opacity: 1;
}
.listRankTitle div {
  opacity: 0.8;
  background: #b0b3b9;
  padding: 0px 18px;
  box-shadow: 0px 0px 9px 0 rgba(29, 32, 33, 0.28);
}

.rankingBody {
  height: 85vh;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
}
.rankingBody .rankingHeader {
  width: 98%;
  display: flex;
  background: #fff;
  box-shadow: 0 0 10px rgba(125, 125, 125, 0.17);
  margin: 0 auto;
  border-radius: 6px;
  padding: 8px 10px;
  color: #6e7482;
  font-weight: bold;
}

.rankingBody .headerBox {
  flex: 1;
  position: relative;
  font-size: 14px;
  text-align: center;
}

.rankingBody .headerBoxSelf {
  text-align: left;
  width: 100%;
  position: relative;
  font-size: 14px;
  text-align: center;
}

.rankingBody .titleHeaderBox {
  width: 30%;
  position: relative;
  font-size: 14px;
  text-align: center;
}
.boxshow {
  box-shadow: 0px 0px 10px rgba(208, 35, 36, 0.05);
}

.lineBody {
  width: 100%;
  height: calc(100% - 10px);
  overflow-y: scroll;
}
.rankingLine {
  width: 98%;
  display: flex;
  /* justify-content: flex-end; */
  background: #fff;
  box-shadow: 0 0 10px rgba(125, 125, 125, 0.17);
  margin: 5px auto;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px 10px;
  /* overflow: hidden; */
  position: relative;
}

.rankingLineSelf {
  display: block;
}

.rankingLine > div {
  margin-top: 5px;
  overflow: hidden;
  /* text-overflow: ellipsis;
  white-space: nowrap; */
  text-align: center;
  /* vertical-align: top; */
  /* flex: 1; */
}
.rankingLine > .number {
  display: inline-block;
  padding: 0 3px;
  /* width: 20px; */
  height: 15px;
  border-bottom-right-radius: 6px;
  font-size: 13px;
  line-height: 15px;
  /* background: rgb(86, 127, 31); */
  /* background: #757575; */
  /* background: #c75833; */
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.rankingLine > .numberGrey {
  background: #757575;
}

.bg01 {
  background: rgb(202, 9, 21);
}
.bg02 {
  background: rgb(252, 103, 46);
}
.bg03 {
  background: rgb(176, 194, 40);
}
.bg04 {
  background: rgb(105, 175, 120);
}
.banganSty {
  min-width: 120px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 6%;
  font-size: 14px;
  background: #1284fd;
  border-radius: 4px;
}
.banganSty .banganStyAcvive {
  background: #3362c7;
  font-weight: bold;
  opacity: 1;
  font-size: 16px;
}
.banganSty div {
  flex: 1;
  opacity: 0.8;
  padding: 0px 10px;
  box-shadow: 0px 0px 9px 0 rgba(29, 32, 33, 0.28);
}

.headerBoxTitle {
  width: 30%;
  padding: 4px 0;
}
</style>
