var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"descTitle",staticStyle:{"display":"flex","justify-content":"flex-end","width":"96%"},style:(_vm.descWidth ? 'padding: 10px 0;' : 'padding: 19px 0;')},[_c('div',{staticClass:"descMain",staticStyle:{"margin-bottom":"10px"}},_vm._l((_vm.columns),function(column,columnIndex){return _c('div',{key:columnIndex,class:_vm.descWidth ? 'tipsTitlefixWidth' : 'tipsTitle'},[(column.name)?_c('span',[_vm._v(" "+_vm._s(column.name)+" "),(column.name.indexOf('环比') > -1)?_c('span',{class:`tips tips${columnIndex + 1}`,style:({ background: 'green' })}):_c('span',{class:`tips tips${columnIndex + 1}`,style:({ background: column.color ? column.color : '' })})]):_c('span',[_vm._v(" "+_vm._s(column)+" "),(column.indexOf('环比') > -1)?_c('span',{class:`tips tips${columnIndex + 1}`,style:({ background: 'green' })}):_c('span',{class:`tips tips${columnIndex + 1}`})])])}),0)]),(_vm.showList.length > 0)?_c('div',{staticClass:"listSty",style:({ height: _vm.listHeight })},[_vm._l((_vm.showList),function(item,key){return _c('div',{key:key},[_c('div',{staticClass:"contentMainItem",staticStyle:{"box-shadow":"0 1px 6px rgb(0 0 0 / 8%)","padding":"7px 4px 7px 20px"}},[(key + 1 <= 10)?_c('span',{staticClass:"number bg01"},[_vm._v(_vm._s(key + 1))]):_vm._e(),(key + 1 <= 50 && key + 1 > 10)?_c('span',{staticClass:"number bg02"},[_vm._v(_vm._s(key + 1))]):_vm._e(),(key + 1 <= 100 && key + 1 > 50)?_c('span',{staticClass:"number bg03"},[_vm._v(_vm._s(key + 1))]):_vm._e(),(key + 1 <= 200 && key + 1 > 100)?_c('span',{staticClass:"number bg04"},[_vm._v(_vm._s(key + 1))]):_vm._e(),(key + 1 > 200)?_c('span',{staticClass:"number",staticStyle:{"background":"#515a6e"}},[_vm._v(_vm._s(key + 1))]):_vm._e(),(_vm.titleSelfLine && _vm.titleSelfTitle)?_c('div',{staticStyle:{"width":"100%","text-align":"left","color":"#aeacac","display":"block"}},[_vm._v(" "+_vm._s(item[_vm.titleSelfTitle])+" ")]):_vm._e(),(
            (item[_vm.title] ||
              item['单位名称'] ||
              item['单位'] ||
              item['姓名'] ||
              item['name'] ||
              item['deptName']) &&
            _vm.singelTitle
          )?_c('div',{staticClass:"titleOverFlow",style:({ width: _vm.columns.length <= 3 ? '40%' : '30%' })},[_vm._v(" "+_vm._s(item[_vm.title] || item["单位名称"] || item["单位"] || item["name"] || item["姓名"] || item["deptName"])+" ")]):_vm._e(),(!_vm.singelTitle)?_c('div',{staticClass:"titleMulti",style:({ width: _vm.columns.length <= 3 ? '40%' : '30%' })},_vm._l((_vm.multiTitleArr),function(titleItem,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item[titleItem.value])+" ")])}),0):_vm._e(),_c('div',{staticClass:"contentMainItem",staticStyle:{"flex":"1"}},_vm._l((_vm.columns),function(column,columnIndex){return _c('div',{key:columnIndex,class:_vm.columns.length <= 3
                ? `contentItemCommon mainTitleTap${columnIndex + 1}`
                : `contentItem mainTitleTap${columnIndex + 1}`,style:({
              color:
                _vm.columns[columnIndex] && _vm.columns[columnIndex]['color']
                  ? _vm.columns[columnIndex]['color']
                  : '',
              padding: '4px 0',
            }),attrs:{"id":_vm.columns[columnIndex] && _vm.columns[columnIndex]['selfValLine']
                ? 'selfValLineClass'
                : ''},on:{"click":function($event){return _vm.chooseItem(item)}}},[_vm._v(" "+_vm._s(columnIndex["value"])+" "),(column.name)?_c('div',[(column == '环比增长' || '同比增长' == column)?_c('span',{},[(
                    item[_vm.columns[columnIndex]['value']] &&
                    item[_vm.columns[columnIndex]['value']].indexOf('+') > -1
                  )?_c('span',{staticClass:"tipsHuanbiupColor"},[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]["value"]])+" ")]):_c('span',{staticClass:"tipsHuanbiDownColor"},[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]["value"]])+" ")])]):_c('span',[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]["value"]]))])]):_c('div',[(column == '环比增长' || '同比增长' == column)?_c('span',{},[(
                    item[_vm.columns[columnIndex]] &&
                    item[_vm.columns[columnIndex]].indexOf('+') > -1
                  )?_c('span',{staticClass:"tipsHuanbiupColor"},[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]])+" ")]):_c('span',{staticClass:"tipsHuanbiDownColor"},[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]])+" ")])]):_c('span',[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]]))])])])}),0)])])}),(_vm.showList.length > 0 && _vm.huizongList.length > 0)?_c('div',_vm._l((_vm.huizongList),function(item,key){return _c('div',{key:`huizong${key}`},[_c('div',{staticClass:"contentMainItem",staticStyle:{"box-shadow":"0 1px 6px rgb(0 0 0 / 8%)","padding":"7px 4px 7px 20px"}},[(key + 1 <= 10)?_c('span',{staticClass:"number bg01"},[_vm._v(_vm._s(key + 1))]):_vm._e(),(key + 1 <= 50 && key + 1 > 10)?_c('span',{staticClass:"number bg02"},[_vm._v(_vm._s(key + 1))]):_vm._e(),(key + 1 <= 100 && key + 1 > 50)?_c('span',{staticClass:"number bg03"},[_vm._v(_vm._s(key + 1))]):_vm._e(),(key + 1 <= 200 && key + 1 > 100)?_c('span',{staticClass:"number bg04"},[_vm._v(_vm._s(key + 1))]):_vm._e(),(key + 1 > 200)?_c('span',{staticClass:"number",staticStyle:{"background":"#515a6e"}},[_vm._v(_vm._s(key + 1))]):_vm._e(),(_vm.titleSelfLine && _vm.titleSelfTitle)?_c('div',{staticStyle:{"width":"100%","text-align":"left","color":"#aeacac","display":"block"}},[_vm._v(" "+_vm._s(item[_vm.titleSelfTitle])+" ")]):_vm._e(),(
              (item[_vm.title] ||
                item['单位名称'] ||
                item['单位'] ||
                item['姓名'] ||
                item['name'] ||
                item['deptName']) &&
              _vm.singelTitle
            )?_c('div',{staticClass:"titleOverFlow",style:({ width: _vm.columns.length <= 3 ? '40%' : '30%' })},[_vm._v(" "+_vm._s(item[_vm.title] || item["单位名称"] || item["单位"] || item["name"] || item["姓名"] || item["deptName"])+" ")]):_vm._e(),(!_vm.singelTitle)?_c('div',{staticClass:"titleMulti",style:({ width: _vm.columns.length <= 3 ? '40%' : '30%' })},_vm._l((_vm.multiTitleArr),function(titleItem,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item[titleItem.value])+" ")])}),0):_vm._e(),_c('div',{staticClass:"contentMainItem",staticStyle:{"flex":"1"}},_vm._l((_vm.columns),function(column,columnIndex){return _c('div',{key:columnIndex,class:_vm.columns.length <= 3
                  ? `contentItemCommon mainTitleTap${columnIndex + 1}`
                  : `contentItem mainTitleTap${columnIndex + 1}`,style:({
                color:
                  _vm.columns[columnIndex] && _vm.columns[columnIndex]['color']
                    ? _vm.columns[columnIndex]['color']
                    : '',
                padding: '4px 0',
              }),attrs:{"id":_vm.columns[columnIndex] && _vm.columns[columnIndex]['selfValLine']
                  ? 'selfValLineClass'
                  : ''},on:{"click":function($event){return _vm.chooseItem(item)}}},[_vm._v(" "+_vm._s(columnIndex["value"])+" "),(column.name)?_c('div',[(column == '环比增长' || '同比增长' == column)?_c('span',{},[(
                      item[_vm.columns[columnIndex]['value']] &&
                      item[_vm.columns[columnIndex]['value']].indexOf('+') > -1
                    )?_c('span',{staticClass:"tipsHuanbiupColor"},[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]["value"]])+" ")]):_c('span',{staticClass:"tipsHuanbiDownColor"},[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]["value"]])+" ")])]):_c('span',[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]["value"]]))])]):_c('div',[(column == '环比增长' || '同比增长' == column)?_c('span',{},[(
                      item[_vm.columns[columnIndex]] &&
                      item[_vm.columns[columnIndex]].indexOf('+') > -1
                    )?_c('span',{staticClass:"tipsHuanbiupColor"},[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]])+" ")]):_c('span',{staticClass:"tipsHuanbiDownColor"},[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]])+" ")])]):_c('span',[_vm._v(" "+_vm._s(item[_vm.columns[columnIndex]]))])])])}),0)])])}),0):_vm._e()],2):_vm._e(),(_vm.showLoadMore && _vm.showList.length <= 0 && !_vm.showLoad)?_c('div',{staticStyle:{"width":"100%","padding":"10px 0","text-align":"center","color":"#795548"}},[_vm._v(" 没有更多数据了 ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }