<style scoped>
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loadding {
  position: fixed;
  top: 20%;
  left: calc(50% - 30px);
  z-index: 2000;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  animation-name: spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation: spin 1s linear infinite;
}
</style>
<template>
  <div class="loadding">
    <svg
      t="1645608155075"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      p-id="18840"
      width="60"
      height="60"
    >
      <path
        d="M511.573 0.853C233.067 0.853 6.55 223.445 0.15 500.416 6.06 258.731 189.824 64.811 415.66 64.811c229.546 0 415.658 200.426 415.658 447.616 0 52.97 42.944 95.936 95.915 95.936 52.95 0 95.915-42.966 95.915-95.936C1023.147 229.888 794.112 0.853 511.573 0.853z m0 1023.147c278.507 0 505.024-222.592 511.403-499.563-5.888 241.664-189.653 435.627-415.488 435.627-229.547 0-415.659-200.448-415.659-447.637 0-52.95-42.965-95.915-95.936-95.915C42.965 416.512 0 459.456 0 512.427 0 794.965 229.035 1024 511.573 1024z"
        p-id="18841"
        fill="rgb(51, 124, 153)"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "loadingCmp",
  props: {},
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {},
  async created() {},
  beforeDestroy() {},
};
</script>
